export * from "./odds";
export * from "./numbers";
export * from "./date";
export * from "./debug";
export * from "./numbers";

export function getClientIp(headers: any) {
    const FALLBACK_IP_ADDRESS = '0.0.0.0';
    const forwardedFor = headers().get('x-forwarded-for');
    if (forwardedFor) {
      return forwardedFor.split(',')[0] ?? FALLBACK_IP_ADDRESS;
    }
    return headers().get('x-real-ip') ?? FALLBACK_IP_ADDRESS;
  }