import { Sport, TeamBasic, getDefaultSeason, getDefaultYear } from "common";
import { toDateString, convertDateToStringWithoutTime } from "./date";

export function createStandingsSlug(sport: Sport, year?: string) {
  return `/${sport}/standings/${year || getDefaultYear(sport)}/division`;
}

export function createTeamSlug(sport: Sport, team: TeamBasic) {
  return `/${sport}/teams/${formatNameToUrl(
    `${team.location}-${team.name}`
  )}/${getDefaultYear(sport)}`;
}

export function createPlayerProfileSlug(sport: Sport, id: number): string {
  return `/${sport}/players/${id}`;
}

export function createTeamSlugFromString(sport: Sport, team: string) {
  return `/${sport}/teams/${formatNameToUrl(team)}/${getDefaultYear(sport)}`;
}
export function createLeaderboardSlug(sport: Sport) {
  if (sport && sport !== "all") {
    return `/${sport}/leaderboard`;
  }
  return `/leaderboard`;
}
export function createProfileSlug(username: string): string {
  return `/profile/${username.toLowerCase()}`;
}

export function createStatsSlug(
  sport: Sport,
  year?: string,
  season?: string,
  category?: string
) {
  if (category) {
    return `/${sport}/stats/${year || "2024"}/${category}`;
  }
  if (year) {
    return `/${sport}/stats/${year}`;
  }
  return `/${sport}/stats/`;
}
export function createGameDetailSlug(sport: Sport, gameId: number): string {
  return `/${sport}/game/${gameId}`;
}
export function createInjuriesSlug(sport: Sport, team?: string) {
  if (team) {
    return `/${sport}/injuries/${team}`;
  }
  return `/${sport}/injuries`;
}
export function createScoresSlug(sport: Sport, date?: string): string {
  return `/${sport}/scores/${date || 
    convertDateToStringWithoutTime(new Date())
  }`;
}

export function getTeamNameUrl(team: TeamBasic): string {
  return formatNameToUrl(`${team.location} ${team.name}`);
}
export function formatNameToUrl(name: string): string {
  return name
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "");
}
